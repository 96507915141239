<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改Vip配置' : '添加Vip配置'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="modalData.name"
          placeholder="请输入名称"
          :maxLength="50"
        ></el-input>
      </el-form-item>


      <el-form-item label="积分区间" prop="minScore">
        <el-row>
          <el-col :span="11">
            <el-input
              v-model="modalData.minScore"
              placeholder="请输入最小积分（包含当前值）"
              :maxLength="50"
            ></el-input>
          </el-col>
          <el-col :span="2" style="text-align: center;">
            ~
        </el-col>
          <el-col :span="11">
            <el-input
              v-model="modalData.maxScore"
              placeholder="请输入最大积分（包含当前值）"
              :maxLength="50"
            ></el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="优惠金额" prop="amount">
        <el-input
          v-model="modalData.amount"
          placeholder="请输入名称"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="modalData.remark"
          placeholder="请输入名称"
          :maxLength="50"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { mapState } from "vuex";

export default {

  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType"],
  data() {
    let operationId = this.$store.getters.userInfo.operationId;
    return {
      operationId: operationId,
      isShowNum: false,
      formName: "form",
      ruleValidate: {
        // operationId: [
        //   {
        //     required: true,
        //     message: "请选择相关代理商",
        //     trigger: "change",
        //   },
        // ],
        goodsMarketPrice: [
          {
            required: true,
            message: "押金金额不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    // 修改使用次数
    changeNum() {
      this.isShowNum = !!this.modalData;
    },

    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);
        obj.amount = obj.amount * 100;
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
